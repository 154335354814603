/* ArcGIS JS API CSS */

@import 'https://js.arcgis.com/4.26/@arcgis/core/assets/esri/themes/light/main.css';
.mapDiv {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }

/* END ArcGIS JS API CSS */



a:hover {
    color: unset;
}

.modebar-group {
    padding-left: 0 !important;
}


/* ADDING TOOLTIPS WITH data-tooltip='this is the tooltip' */

[data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    
    /* customizable */
    transition: all 0.15s ease;
    padding: 10px;
    color: #333;
    border-radius: 10px;
    box-shadow: 2px 2px 1px silver;    
}

[data-tooltip]:hover:before {
    /* needed - do not touch */
    opacity: 1;
    
    /* customizable */
    background: yellow;
    margin-top: -50px;
    margin-left: 20px;    
}


/* FOR PERSISTENT TOOLTIPS use data-tooltip-persistent*/

[data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
}